//
// Jumbotron
// --------------------------------------------------


.jumbotron {
  padding: @jumbotron-padding (@jumbotron-padding / 2);
  color: @jumbotron-color;
  background: @jumbotron-bg url("@{images-url}bg-jumbotron.jpg");
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: contain;

  h1,
  .h1 {
    color: @jumbotron-heading-color;
	  font-size: 38px;
	  margin: 0;
	  letter-spacing: 4px;
  }

	h2 {
		text-transform: none;
		font-family: lato, sans-serif;
		font-size: 24px;
		font-weight: 300;
		margin-top: 0;
		letter-spacing: 1px;
	}

  p {
    margin-bottom: (@jumbotron-padding / 2);
    font-size: @jumbotron-font-size;
    font-weight: 200;
  }

  hr {
    border-top-color: @arany;
  }

  .container &,
  .container-fluid & {
    border-radius: @border-radius-large; // Only round corners at higher resolutions if contained in a container
  }

  .container {
    max-width: 100%;
  }

	@media (max-width: @screen-xs-max){
		background-image: url("@{images-url}bg-jumbotron-mobil.jpg");
		background-size: 100%;
		background-position: 50% 0;
		padding-top: 90vw;

		h1 {
			font-size: 21px;
			letter-spacing: 1px;
		}

		h2,p {
			font-size: @font-size-small;
		}
	}

  	@media screen and (min-width: @screen-sm-min) {
		padding-top: 60px;
		height: 375px;

		.container &,
		.container-fluid & {
		  padding-left:  (@jumbotron-padding * 2);
		  padding-right: (@jumbotron-padding * 2);
		}
	}
}
