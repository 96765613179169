@import "fonts";
@import "../vendor/font-awesome/less/font-awesome.less";
/*@import "../vendor/smalot-bootstrap-datetimepicker/less/datetimepicker.less";*/
@import "../vendor/eonasdan-bootstrap-datetimepicker/src/less/_bootstrap-datetimepicker.less";
@import "bootstrap/bootstrap";

label {
	font-size: 15px;
	line-height: 18px;
	font-family: 'museo_sans500_italic';
	color: #777;
	font-weight: normal;
}

dt {
	font-family: 'museo_sans900';
	font-size: 18px;
	margin: 0 0 10px;
}

dd {
	font-family: 'museo_sans500_italic';
	font-size: 15px;
}

.vh-100 {
	min-height: 100vh;
	padding: 100px 0 60px;

	.disp-table {
		height: 70vh;
	}
}

section.cim {
	padding: 100px 0 1px;
}

.disp-table {
	display: table;
	width: 100%;
}

.disp-table-cell {
	display: table-cell;
	vertical-align: middle;
}

.disp-table-cell {
	height: 100%;
}

.relative {
	position: relative;
}

.hatter {
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: 1;
	text-align: right;

	img, video {
		width: 41%;
		height: auto;
	}
}

.eloter {
	width: 100%;
	height: 100%;
	position: relative;
	z-index: 2;
}

.italic {
	font-family: 'museo_sans500_italic';
}

.narancs-design {
	h2,
	.lead,
	.btn {
		color: @narancs;
	}

	.btn:hover {
		background-color: @narancs;
		color: #fff;
	}
}

.piros-design {
	h2,
	h3,
	.lead,
	.btn,
	.help-block {
		color: @piros;
	}

	h3 {
		font-size: 25px;
	}

	.btn {
		margin: 0 30px 0 0;
		padding-left: 50px;

		&.csillag {
			margin-bottom: 15px;
			background: url("@{images-url}csillag.png") no-repeat 15px center transparent;

			&:hover {
				background: url("@{images-url}csillag_hover.png") no-repeat 15px center @piros;
			}
		}

		&.play {
			background: url("@{images-url}play.png") no-repeat 20px center transparent;
			margin-right: 0 !important;
			margin-bottom: 15px;

			&:hover {
				background: url("@{images-url}play_hover.png") no-repeat 20px center @piros;
			}
		}
	}

	.btn:hover {
		background-color: @piros;
		color: #fff;
	}

	.icon-holder {
		margin: 40px 0;
	}

	.ikonos {
		padding: 0 0 0 75px;
		color: #535353;

		.szoveg {
			margin: 0;
		}

		&.tarsadalom {
			background: url("@{images-url}tarsadalom_bg.png") no-repeat 0 0 transparent;
		}

		&.kornyezet {
			background: url("@{images-url}kornyezet_bg.png") no-repeat 0 0 transparent;
		}

		&.termeles {
			background: url("@{images-url}termeles_bg.png") no-repeat 0 0 transparent;
		}
	}

	#energopoly-bg {
		background: url("@{images-url}energopoly_bg.png") no-repeat center bottom transparent;
		padding-bottom: 485px;
	}
}

.energopoly-container {
	padding-bottom: 0;
}

.szoveg {
	margin: 50px 0 30px;

	.col-md-6:first-child {
		margin-left: 0;
		padding-left: 0;
	}

	.col-md-6:last-child {
		margin-right: 0;
		padding-right: 0;
	}
}

.szoveg.light {
	font-family: 'museo_sans300';
	font-size: 15px;
	line-height: 22px;
}

.tovabb,
.vissza {
	font-size: 0;
	text-indent: -8000px;
	display: block;
	margin: 0 auto;
	position: absolute;
}

.tovabb {
	width: 81px;
	height: 80px;
	background: url("@{images-url}tovabb.png") no-repeat center center transparent;
	bottom: 25px;
	left: 0;
	right: 0;
	z-index: 5;
}

.vissza {
	width: 60px;
	height: 60px;
	left: 25px;
	top: 112px;
}

.fel {
	position: fixed;
	z-index: 10;
	bottom: 60px;
	right: 15px;
	display: block;
	width: 60px;
	height: 60px;
	background: url("@{images-url}fel.png") no-repeat center center transparent;
}

#zold-zona-latogatokozpont {
	.vissza {
		background: url("@{images-url}vissza_zold.png") no-repeat center center transparent;
	}
	
}

#zold-energia-vetelkedo {
	.vissza {
		background: url("@{images-url}vissza_narancs.png") no-repeat center center transparent;
	}
}

#energopoly {
	.vissza {
		background: url("@{images-url}vissza_piros.png") no-repeat center center transparent;
	}
}

.sima {
	padding: 40px 0;
}

.hover-zold:hover {
	color: @zold;
}

.hover-white-bg:hover {
	background-color: #fff !important;
}

.hover-narancs:hover {
	color: @narancs;
}

.hover-piros:hover {
	color: @piros;
}

.border-zold {
	border-color: @zold !important;
}

.border-narancs {
	border-color: @narancs !important;
}

.border-piros {
	border-color: @piros !important;
}

.zold-bg {
	background-color: @zold !important;
}

.narancs-bg {
	background-color: @narancs !important;
}

.piros-bg {
	background-color: @piros !important;
}

body {

	#zold-zona-latogatokozpont,
	#tuzeloanyagok {
		background-image: url("@{images-url}bg/index_zold_zona_bal.png"), url("@{images-url}bg/index_zold_zona_jobb.png");
		background-repeat: no-repeat;
		background-position: left bottom, right top;
		background-color: @zold;
	}

	#zold-zona-latogatokozpont {

		&.aloldal {
			background-image: url("@{images-url}bg/zold_zona_bal.png"), url("@{images-url}bg/zold_zona_jobb.png");
			background-repeat: no-repeat;
			background-position: left bottom, right top 70px;
		}

		.h2_holder {
			background: url("@{images-url}latogatokozpont_head_bg.png") no-repeat center top transparent;
		}
	}

	#zold-energia-vetelkedo {
		background-image: url("@{images-url}bg/index_vetelkedo_jobb.png"), url("@{images-url}bg/index_vetelkedo_bal.png");
		background-repeat: no-repeat;
		background-position: right bottom, left top;
		background-color: @narancs;

		&.aloldal {
			background-image: url("@{images-url}bg/vetelkedo_bal.png"), url("@{images-url}bg/vetelkedo_jobb.png");
			background-repeat: no-repeat;
			background-position: left top 70px, right bottom;
		}

		.h2_holder {
			background: url("@{images-url}vetelkedo_head_bg.png") no-repeat center top transparent;
		}
	}

	#energopoly {
		background-image: url("@{images-url}bg/index_energopoly_bal.png"), url("@{images-url}bg/index_energopoly_jobb.png");
		background-repeat: no-repeat;
		background-position: left bottom, right top;
		background-color: @piros;

		&.aloldal {
			background-image: url("@{images-url}bg/energopoly_bal.png"), url("@{images-url}bg/energopoly_jobb.png");
			background-repeat: no-repeat;
			background-position: left bottom, right top 70px;
		}

		.h2_holder {
			background: url("@{images-url}energopoly_head_bg.png") no-repeat center top transparent;
		}

		.szoveg {
			margin: 5px 0 30px;
		}
	}

	#memoriajatek {
		background-image: url("@{images-url}bg/index_vetelkedo_jobb.png"), url("@{images-url}bg/index_vetelkedo_bal.png");
		background-repeat: no-repeat;
		background-position: right bottom, left top;
		background-color: @narancs;

		&.aloldal {
			background-image: url("@{images-url}bg/vetelkedo_bal.png"), url("@{images-url}bg/vetelkedo_jobb.png");
			background-repeat: no-repeat;
			background-position: left top 70px, right bottom;
		}

		.h2_holder {
			background: url("@{images-url}memoriajatek_head_bg.png") no-repeat center top transparent;
			background-size: 70px 70px;
		}
		.ui-sortable *{
			cursor:move;
		}
	}

	#memoriajatek,
	#zold-zona-latogatokozpont,
	#zold-energia-vetelkedo,
	#energopoly {
		color: #fff;
		font-size: 18px;
		line-height: 32px;

		.h2_holder {
			padding: 90px 0 0;
			width: 450px;
			margin: 0 auto 60px;

			@media (max-width: 500px) {
				width: 100%;
			}
		}

		h2 {
			color: #fff;
			text-align: center;
			background: url("@{images-url}h2_bg.png") no-repeat center bottom transparent;
			padding: 0 0 40px;
			font-size: 55px;

			@media (max-width: 767px) {
				font-size: 35px;
			}

			@media (max-width: 479px) {
				font-size: 25px;
			}
		}
	}

	#zold-energia {
		background: url("@{images-url}bg_pattern.png") repeat 0 0 transparent;
		font-size: 20px;
		line-height: 36px;
		color: #777777;
		overflow: hidden;

		h2 {
			color: @zold;
			font-size: 60px;
			margin: 0 0 30px;

			@media (max-width: 767px) {
				font-size: 40px;
			}

			@media (max-width: 479px) {
				font-size: 25px;
			}
		}
	}

	#megkozelites {
		background: url("@{images-url}megkozelites_bg2.jpg") no-repeat center 0 transparent;
		padding-bottom: 0;

		@media (max-width: 479px) {
			padding-top: 50px;
		}
	}

	.vilagos,
	.zold-bg,
	.megkozelites {

		h2 {
			font-size: 35px;
			margin: 0 0 20px;
			max-width: 500px;
		}

		.szoveg {
			font-family: 'museo_sans300';
			font-size: 15px;
			line-height: 22px;
		}
	}

	.vilagos {
		background: url("@{images-url}bg_pattern.png") repeat 0 0 transparent;
		color: @zold;

		.szoveg {
			color: #777;

			.help-block {
				display: block;
				margin-top: 30px;
			}
		}

		&.message {
			padding: 70px 0 0;
		}
		&.narancs-design {
			color: @narancs;
		}
	}

	.message + .vh-100 {
		padding-top: 0;
	}

	.megkozelites {
		min-height: 535px;
		font-size: 18px;
		color: #fff;
		font-family: 'museo_sans500';
//		padding: 86px 0 0;

		h2 {
			margin-bottom: 40px;
		}

		.info {
			margin-bottom: 50px;

			.help-block {
				color: #fff;
			}
		}

		h2,
		h3 {
			color: @zold;
		}

		h3 {
			font-size: 20px;
			line-height: 34px;
			margin: 0 0 10px;
		}

		ul {
			padding: 0 0 0 15px;

			li {
				list-style-position: outside;
				text-indent: 5px;
				color: @zold;
				list-style-image: url("@{images-url}lista_bg.png");
				line-height: 30px;

				span {
					color: #fff;
				}
			}
		}

		.buszok {
			@busz-height: 60px;
			margin-top: 50px;
			height: @busz-height;
			background: url("@{images-url}busz_bg.png") no-repeat 0 center transparent;
			padding: 0 0 0 70px;

			@media (max-width: 479px) {
				margin-top: 25px;
			}

			li {
				text-indent: 0;

				a {
					display: block;
					width: @busz-height;
					height: @busz-height;
					line-height: @busz-height;
					.border-radius(@busz-height / 2);
					border: 1px solid @zold;
					color: @zold;
					font-size: 25px;
					font-family: 'museo_sans900';
					text-align: center;
					background-color: transparent;

					&:hover {
						color: #fff;
						background-color: @zold;
					}

					&:hover,
					&:active,
					&:visited,
					&:focus {
						text-decoration: none;
					}
				}
			}
		}
	}

	.captcha-label {
		color: @zold;
		font-size: 18px;
		font-family: 'museo_sans900';
		padding: 0 0 10px;
	}

	.help-div {
		font-family: 'museo_sans500_italic';
		font-size: 14px;
		line-height: 17px;
		color: #aaa;
		background: url("@{images-url}helpdiv_bg.png") no-repeat 0 0 transparent;
		padding: 0 0 0 37px;
	}

	#gmap {
		height: 400px;
		margin: 0;
	}

	.kitoltendo {
		font-family: 'museo_sans900';
		font-size: 18px;
		line-height: 21px;
	}

	.zold-bg {
		background-color: @zold;
		color: #fff;
	}

	.lead {
		font-family: 'museo_sans500_italic';
		font-size: 20px;
		line-height: 30px;
	}

	.jegyzet {
		font-size: 16px;
		font-family: 'museo_sans700_italic';
		line-height: 19px;
		position: absolute;
		width: 50%;
		height: auto;
		top: 19%;
		right: 0;

		span {
			display: block;
		}
	}

	#megjegyzes {
		height: 140px;
	}

	#nyitvatartas-es-bejelentkezes {
		.btn {
			color: @zold;

			&:hover {
				color: #fff;
				background-color: @zold;
			}
		}
	}

	.uresdiv {
		width: 0;
		height: 0;
		position: relative;
	}

	.error-div {
//		padding: 0 0 34px;
		position: absolute;
		left: 0px;
		bottom: 50px;

		dl {
			background-color: @zold;
			color: #fff;
			padding: 20px;
			.border-radius(20px);
			position: relative;
			width: 360px;
			.box-shadow(0 10px 25px rgba(0, 0, 0, 0.5));

			&:after {
				content: ' ';
				position: absolute;
				bottom: -34px;
				left: 40px;
				width: 0;
				height: 0;
				border-top: 34px solid @zold;
				border-right: 30px solid transparent;
			}
		}
	}
}

input.datepicker, input.datepicker2 {
	width: 83%;
	float: left;
}

.add-on {
	float: right;
	display: block;

	.icon-th:after {
		display: block;
		content: ' ';
		width: 36px;
		height: 36px;
		background: url("@{images-url}naptar_ikon.png") no-repeat center center transparent;
	}
}

.video-container {
	margin: 0 0 60px;
}

.flowplayer {
	background: url("@{images-url}splash.png") no-repeat center center transparent;
}

.grid:after {
	content: '';
	display: block;
	clear: both;
}

.grid-item {
	float: left;
	width: 25%;
	height: auto;

	a {
		display: block;
		position: relative;

		&:after {
			content: ' ';
			display: block;
			width: 100%;
			height: 100%;
			position: absolute;
			background: url("@{images-url}image_hover.png") no-repeat center center transparent;
			border: 10px solid @zold;
			top: 0;
			left: 0;
			.transition(all 0.3s ease-in);
			.opacity(0);
		}

		.transition(all 0.3s ease-in);

		&:hover {
//			border: 10px solid @zold;
			&:after {
				.opacity(100);
			}
		}
	}
}

.grid-item--width2 {
	width: 50%;
}

.datetimepicker {
	table {
		table-layout: fixed !important;
		border-collapse: collapse;
	}
}



.datetimepicker thead tr:first-child {
	th {
		padding: 10px;
	}
}

.datetimepicker td, .datetimepicker th {
	text-align: center;
	width: 20px;
	height: 20px;
	.border-radius(0);
	border: none;
}

.datetimepicker {
	i.icon-arrow-left,
	i.icon-arrow-right {
		width: 30px;
		height: 30px;
		display: block;
		.border-radius(15px);

		&:hover {
			border: 1px solid #fff;
		}
	}

	i.icon-arrow-left {
		background: url("@{images-url}prev.png") no-repeat center center transparent;
	}

	i.icon-arrow-right {
		background: url("@{images-url}next.png") no-repeat center center transparent;
	}
}

.datetimepicker td, .datetimepicker th {
	width: 28px;
	height: 28px;
}

.datetimepicker thead tr:last-child {
	th {
		background-color: #fff;
		color: @zold;
		font-size: 13px;
		font-weight: normal;
		padding: 20px 10px;
	}
}

[class*="datetimepicker-dropdown"]:after {
	content: '';
	display: inline-block;
	border-left: 6px solid transparent;
	border-right: 6px solid transparent;
	border-bottom: 6px solid @zold;
	position: absolute;
}

.datetimepicker thead tr:first-child {
	background-color: @zold;
}

.datetimepicker thead tr:first-child th,
.datetimepicker tfoot tr:first-child th,
.datetimepicker thead tr:first-child th:hover,
.datetimepicker tfoot tr:first-child th:hover {
	background-color: @zold;
	color: #fff;
	text-transform: none;
}

.datetimepicker table tr td.day:hover {
	background: #f2f1ed;
	cursor: pointer;
	.border-radius(14px);
}

.datetimepicker table tr td span {
	.border-radius(14px);
}

.datetimepicker table tr td.active:hover,
.datetimepicker table tr td.active:hover:hover,
.datetimepicker table tr td.active.disabled:hover,
.datetimepicker table tr td.active.disabled:hover:hover,
.datetimepicker table tr td.active:active,
.datetimepicker table tr td.active:hover:active,
.datetimepicker table tr td.active.disabled:active,
.datetimepicker table tr td.active.disabled:hover:active,
.datetimepicker table tr td.active.active,
.datetimepicker table tr td.active:hover.active,
.datetimepicker table tr td.active.disabled.active,
.datetimepicker table tr td.active.disabled:hover.active,
.datetimepicker table tr td.active.disabled,
.datetimepicker table tr td.active:hover.disabled,
.datetimepicker table tr td.active.disabled.disabled,
.datetimepicker table tr td.active.disabled:hover.disabled,
.datetimepicker table tr td.active[disabled],
.datetimepicker table tr td.active:hover[disabled],
.datetimepicker table tr td.active.disabled[disabled],
.datetimepicker table tr td.active.disabled:hover[disabled],
.datetimepicker table tr td span.active:hover,
.datetimepicker table tr td span.active:hover:hover,
.datetimepicker table tr td span.active.disabled:hover,
.datetimepicker table tr td span.active.disabled:hover:hover,
.datetimepicker table tr td span.active:active,
.datetimepicker table tr td span.active:hover:active,
.datetimepicker table tr td span.active.disabled:active,
.datetimepicker table tr td span.active.disabled:hover:active,
.datetimepicker table tr td span.active.active,
.datetimepicker table tr td span.active:hover.active,
.datetimepicker table tr td span.active.disabled.active,
.datetimepicker table tr td span.active.disabled:hover.active,
.datetimepicker table tr td span.active.disabled,
.datetimepicker table tr td span.active:hover.disabled,
.datetimepicker table tr td span.active.disabled.disabled,
.datetimepicker table tr td span.active.disabled:hover.disabled,
.datetimepicker table tr td span.active[disabled],
.datetimepicker table tr td span.active:hover[disabled],
.datetimepicker table tr td span.active.disabled[disabled],
.datetimepicker table tr td span.active.disabled:hover[disabled] {
	background-color: @zold;
	.border-radius(14px);
}

.fancybox-overlay {
	overflow: hidden !important;
	background: url("@{images-url}fancy_overlay.png") repeat 0 0 transparent;
}

.fancybox-lock {
	overflow: auto;
}

.fancybox-skin {
	.border-radius(0);

	.fancybox-inner {
//		background: url(../img/bg.png) repeat 0 0 transparent;
	}
}

#hirlevel_feliratkozas {
	padding: 20px;
}

.fancybox-nav {
	span {
		visibility: visible;
		width: 30px;
		height: 60px;
		margin-top: -30px;
		background: url("@{images-url}fancy-sprite.png") no-repeat 0 0 transparent;
	}
	&.fancybox-prev {
		span {
			left: -65px;
			background-position: 0 0;
		}
		&:hover {
			span {
				background-position: -100px 0;
			}
		}
	}
	&.fancybox-next {
		span {
			right: -65px;
			background-position: 0 -100px;
		}
		&:hover {
			span {
				background-position: -100px -100px;
			}
		}
	}
}

.fancybox-close {
	background: url("@{images-url}fancy-sprite.png") no-repeat 0 -200px transparent;
	width: 56px;
	height: 56px;
	top: 0;
	right: -77px;

	&:hover {
		background-position: -100px -200px;
	}

	@media (max-width: 991px) {
		right: 0;
		top: -77px;
	}
}

.fancybox-opened {
	.fancybox-title {
		text-align: right;
		color: #fff;
		font-size: 16px;
	}
}

#ajax-modal {
	.modal-dialog {
		.zold-bg {
			background: url("@{images-url}bejelentkezes_bg.png") no-repeat right top 20px @zold !important;
			color: #fff !important;

			label {
				color: #fff !important;
				font-size: 20px;
				font-family: 'museo_sans500_italic';
			}

			.text-muted {
				color: #fff !important;
			}
		}
	}
}

.alpha-list{
	list-style:lower-alpha;
}

form.kerdoiv {
	li {
		margin: 5px 0 15px 0;
	}
	ol > li {
		margin-bottom: 35px;
	}
	.inlineblock {
		display: inline-block !important;
	}
	p.small {
		font-size: 14px;
	}
	label {
		display: inline;
	}
}

@import "header";
@import "footer";


#rendezheto.ui-sortable img{
	width: 250px;
	float: left;
	vertical-align: middle;
	margin: 5px;
	height: 170px;
}

#rolunk-irtak {
	.media {
		padding: 20px;
		border-radius: 17px;
	}
}

.kisiskolasok_flekk {
	display: block;
	position:absolute;
	right: 0;
	top: 70px;
}
