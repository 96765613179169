body > footer {
	height: 50px;
	background-color: #555555;
	line-height: 50px;
	color: #cccccc;
	font-size: 14px;

	#copy {
		margin: 0 0 0 25px;
	}

	#pannonpower,
	#veolia {
		display: block;
		height: 50px;
		margin: 0 30px 0 0;
		font-size: 0;
		text-indent: -8000px;
	}

	#pannonpower {
		background: url("@{images-url}pannonpower_bg.png") no-repeat 0 center transparent;
		width: 112px;
	}

	#veolia {
		background: url("@{images-url}veolia_bg.png") no-repeat 0 center transparent;
		width: 115px;
	}

}