@fonts-url:	"../../fonts/";

@font-face {
	font-family: 'museo_sans300';
	src: url('@{fonts-url}museo/museosans_300-webfont.eot');
	src: url('@{fonts-url}museo/museosans_300-webfont.eot?#iefix') format('embedded-opentype'),
	url('@{fonts-url}museo/museosans_300-webfont.woff') format('woff'),
	url('@{fonts-url}museo/museosans_300-webfont.ttf') format('truetype'),
	url('@{fonts-url}museo/museosans_300-webfont.svg#museo_sans300') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'museo_sans500';
	src: url('@{fonts-url}museo/museosans_500-webfont.eot');
	src: url('@{fonts-url}museo/museosans_500-webfont.eot?#iefix') format('embedded-opentype'),
	url('@{fonts-url}museo/museosans_500-webfont.woff') format('woff'),
	url('@{fonts-url}museo/museosans_500-webfont.ttf') format('truetype'),
	url('@{fonts-url}museo/museosans_500-webfont.svg#museo_sans500') format('svg');
	font-weight: normal;
	font-style: normal;

}

@font-face {
	font-family: 'museo_sans500_italic';
	src: url('@{fonts-url}museo/museosans_500_italic-webfont.eot');
	src: url('@{fonts-url}museo/museosans_500_italic-webfont.eot?#iefix') format('embedded-opentype'),
	url('@{fonts-url}museo/museosans_500_italic-webfont.woff') format('woff'),
	url('@{fonts-url}museo/museosans_500_italic-webfont.ttf') format('truetype'),
	url('@{fonts-url}museo/museosans_500_italic-webfont.svg#museo_sans500_italic') format('svg');
	font-weight: normal;
	font-style: normal;

}

@font-face {
	font-family: 'museo_sans700';
	src: url('@{fonts-url}museo/museosans_700-webfont.eot');
	src: url('@{fonts-url}museo/museosans_700-webfont.eot?#iefix') format('embedded-opentype'),
	url('@{fonts-url}museo/museosans_700-webfont.woff') format('woff'),
	url('@{fonts-url}museo/museosans_700-webfont.ttf') format('truetype'),
	url('@{fonts-url}museo/museosans_700-webfont.svg#museo_sans700') format('svg');
	font-weight: normal;
	font-style: normal;

}

@font-face {
	font-family: 'museo_sans700_italic';
	src: url('@{fonts-url}museo/museosans_700_italic-webfont.eot');
	src: url('@{fonts-url}museo/museosans_700_italic-webfont.eot?#iefix') format('embedded-opentype'),
	url('@{fonts-url}museo/museosans_700_italic-webfont.woff') format('woff'),
	url('@{fonts-url}museo/museosans_700_italic-webfont.ttf') format('truetype'),
	url('@{fonts-url}museo/museosans_700_italic-webfont.svg#museo_sans700_italic') format('svg');
	font-weight: normal;
	font-style: normal;

}

@font-face {
	font-family: 'museo_sans900';
	src: url('@{fonts-url}museo/museosans_900-webfont.eot');
	src: url('@{fonts-url}museo/museosans_900-webfont.eot?#iefix') format('embedded-opentype'),
	url('@{fonts-url}museo/museosans_900-webfont.woff') format('woff'),
	url('@{fonts-url}museo/museosans_900-webfont.ttf') format('truetype'),
	url('@{fonts-url}museo/museosans_900-webfont.svg#museo_sans900') format('svg');
	font-weight: normal;
	font-style: normal;

}