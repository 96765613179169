body > header {
	background: @fomenu;

	#legfelso {
		@height: 70px;
		color: @legfelso-szurke;
		background: #fff;
		position: fixed;
		width: 100%;
		left: 0;
		top: 0;
		z-index: 12;
		padding: 0 25px;
		height: @height;

		.fb-like {
			margin: (@height - 20px) / 2 0;
			padding: 0;
		}

		.pull-left {
			position: relative;
			z-index: 100000;
		}

		#logo {
			max-width: 249px;
			height: 90px;
			font-size: 0;
			text-indent: -8000px;
			background: url("@{images-url}logo.png") no-repeat 0 center transparent;
			background-size: contain;
			display: block;

			h1 {
				margin: 0;
				padding: 0;
			}
		}

		#banner {
			position: fixed;
			width: 100%;
			left: 0;
			top: 0;
			z-index: -1;
			padding: 0 25px;
			height: 180px;
			background: url(/img/banner.jpg);
			background-position: 20% 50%;
			background-size: contain;
		}

		.login {
			height: @height;
			line-height: @height;
			margin: 0 8px 0 0;

			a {
				color: @gray-light;
				font-size: 12px;
				text-transform: uppercase;
			}
		}

		.socialmedia {
			display: none;
			a {
				display: block;
				float: left;
				height: 34px;
				width: 34px;
				font-size: 0;
				text-indent: -8000px;
				margin: 18px 0 0 7px;

				&.fb {
					background: url("@{images-url}ikon_sprite.png") no-repeat 0 0 transparent;

					&:hover {
						background-position: -50px 0;
					}
				}
				&.tw {
					background: url("@{images-url}ikon_sprite.png") no-repeat 0 -50px transparent;

					&:hover {
						background-position: -50px -50px;
					}
				}
			}
		}

		.header-right {
			z-index: 100;
			position: relative;
		}
	}

	.navbar-default {
		background-color: transparent;
		z-index: 14;
	}

	#menu {
		position: fixed;
		top: 115px;
		left: 25px;

		@ikonmeret: 90px;

		.brand {
			display: block;
			width: @ikonmeret;
			height: @ikonmeret;
			font-size: 0;
			text-indent: -8000px;
			outline: none;
			background-color: #fff;
			margin: 0 0 20px;
			.border-radius(@ikonmeret / 2);
			border: 1px solid;

			span {
				display: block;
				width: 100%;
				height: 100%;
				.scale(0.7778);
				.border-radius(@ikonmeret / 2);
				.transition(all 0.15s ease-in);

				svg {
					display: block;
					fill: #fff;
					.scale(0.5);
					width: 100%;
					height: 100%;
				}
			}

			&:hover {
				span {
					.scale(0.8889);
				}
			}

			&.latogatokozpont {
				span {
//					background: url("@{images-url}zold_zona_eromu_ikon.svg") no-repeat center center transparent;
//					background-size: 50% 50%;
//					fill: #fff;
				}
			}
		}
	}

	.login-xs {
		background: @arany;
		border-radius: 3px;
		margin-top: @grid-gutter-width;

		.btn {
			padding-top: .8em;
			padding-bottom: .8em;
		}

		div:not(:first-child) > .btn:before {
			content: '\01C0';
			position: absolute;
			display: inline-block;
			left: 0;
		}
	}
}

@media (max-width: 991px) {
	body > header #legfelso .login {
		margin-right: 0;

		a {
			font-size: 0;
			text-indent: -8000px;
			width: 34px;
			height: 34px;
			display: block;
			margin-top: 18px;
			background: url("@{images-url}ikon_sprite.png") no-repeat 0 -100px transparent;

			&:hover {
				background-position: -50px -100px;
			}
		}
	}
}

@media (max-width: 330px) {
	.list-inline > li {
		padding-left: 0;
		padding-right: 0;
	}
}

@media (min-width: 992px) and (max-width: 1049px) {
	.navbar-default .navbar-nav > li > a {
		font-size: 14px;
	}
}

@media (min-width: 1050px) and (max-width: 1154px) {
	.navbar-default .navbar-nav > li > a {
		font-size: 16px;
	}
}

@media (max-width: 991px) {

	.navbar-default .navbar-collapse {
		background-color: @zold;
		color: #fff;
	}

	.navbar-default .navbar-nav > li > a,
	.navbar-default .navbar-nav > li > a:visited {
		color: #fff;
		text-align: right;

		&:hover,
		&:active {
			color: @narancs;
		}
	}
}

/*
@media (min-width: 1155px) and (max-width: 1245px) {
	.navbar-default .navbar-nav > li > a {
		font-size: 16px;
	}
}

@media (min-width: 1245px) {
	.navbar-default .navbar-nav > li > a {
		font-size: 18px;
	}
}*/
